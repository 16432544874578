import consumer from "./consumer"
import CableReady from 'cable_ready'


consumer.subscriptions.create({ channel: "PaymentChannel", payer: "stream" }, {
  received(data) {
    const event = new CustomEvent('payment:succeed', { bubbles: true, detail: { status: data.operations.insertAdjacentHtml[0].status, payment_id: data.operations.insertAdjacentHtml[0].paymentId, payment_uid: data.operations.insertAdjacentHtml[0].paymentUid }});
    document.dispatchEvent(event);
    if (data.cableReady) CableReady.perform(data.operations)
    console.log(data.operations)
  }
})