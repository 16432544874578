import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "missCode" ];
  
  initValue(e) {
    e.preventDefault();
    var missCode = this.missCodeTarget.value;
    document.querySelector('.input-miss-code').value = missCode;
    document.querySelector('.candidate-number').textContent = missCode;
    
    // var voterRequestsId = document.getElementById('voter_requests').id || document.getElementById(`voter_requests_${missCode}`).id;
    // voterRequestsId = `voter_requests_${missCode}`;
  }
}
